<template>
    <div id="page-report">
      <div class="row mb-2 pe-4">
        <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">SALES REPORT</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
              <div class="row">
                <h2> POS Collections </h2>
              </div>
              <FormSearch
                :searchParameter="form"
                :onChangeSearchParameter="onChangeSearchParameter"
                @reload-data="getList"
                @export="exportFile"
              ></FormSearch>
              <div class="row mt-4" style="min-height: 1120px" v-if="isLoadDone" v-show="isLoadDoneDataTable">
                <div class="col-12">
                  <div>
                    <div class="px-0 pb-2">
                      <div class="p-0 custom-table">
                        <table class="table align-items-center mb-0 dataTable" ref="dataTable">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder">
                                <div class="pt-4">Row No</div>
                              </th>
                              <template
                                v-for="(item, index) in headersData"
                                :key="index"
                              >
                                <th class="text-center font-weight-bolder position-relative">
                                  <div
                                    class="pt-4"
                                    style="position: unset;"
                                    :class="[
                                      !!item.hasSortBy
                                        ? 'wrapper-sortby pointer'
                                        : '',
                                      ESortDirectionSelect.idx == index + 1
                                        ? ESortDirectionSelect.direction ===
                                          ESortDirection.Desc
                                          ? 'sort-desc'
                                          : 'sort-asc'
                                        : '',
                                    ]"
                                    @click="handleChangeSortColumn(index + 1, item.field)"
                                    >{{ item.label }}</div
                                  >
                                </th>
                              </template>
                              <th class="text-center font-weight-bolder position-relative" v-for="(paymentMethod, index) in paymentMethods" :key="headersData.length + (index + 1)">
                                <div
                                    class="pt-4"
                                    style="position: unset;"
                                    :class="[
                                      'wrapper-sortby pointer',
                                      ESortDirectionSelect.idx == headersData.length + (index + 1)
                                        ? ESortDirectionSelect.direction ===
                                          ESortDirection.Desc
                                          ? 'sort-desc'
                                          : 'sort-asc'
                                        : '',
                                    ]"
                                    @click="handleChangeSortColumn(headersData.length + (index + 1), 'payment_method', index)"
                                    >{{ paymentMethod }}</div
                                  >
                              </th>
                            </tr>
                            <tr>
                              <th class="text-center font-weight-bolder">
                                <input
                                  v-model="columnSearch[0]"
                                  @input="searchColumn(0)"
                                />
                              </th>
                              <template
                                v-for="(item, index) in headersData"
                                :key="index"
                              >
                                <th class="text-center font-weight-bolder">
                                  <input
                                    v-model="columnSearch[index + 1]"
                                    @input="searchColumn(index + 1)"
                                  />
                                </th>
                              </template>
                              <th class="text-center font-weight-bolder" v-for="(paymentMethod, index) in paymentMethods" :key="headersData.length + (index + 1)">
                                <input
                                  v-model="
                                    columnSearch[headersData.length + (index + 1)]
                                  "
                                  @input="searchColumn(headersData.length + (index + 1))"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="transactionData.length">
                            <tr
                              v-for="(value, key) in transactionData"
                              :key="key"
                              :class="{'total-row': key + 1 == transactionData.length}"
                            >
                              <td class="text-right text-xss">
                                {{ key != transactionData.length - 1 ? key + 1 : '' }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.date }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.operation_id }}
                              </td>
                              <td class="text-left text-xss ">
                                {{ value.first_order }}
                              </td>
                              <td class="text-left text-xss">
                                {{ value.last_order }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.sub_total) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.discount) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.gst) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.service_charge) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ parseFloat(value.rounding).toFixed(2) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.total) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.redemption) }}
                              </td>
                              <td class="text-right text-xss">
                                {{ value.bill_count }}
                              </td>
                              <td class="text-right text-xss">
                                {{ formatPrice(value.bill_avg) }}
                              </td>
                              <td class="text-right text-xss" v-for="(paymentMethod, index) in value.payment_methods" :key="index">
                                {{ formatPrice(paymentMethod.amount) }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td :colspan="11 + paymentMethods.length" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
      <form
        :action="`${actionDownload}/merchant/analytics/pos-daily-collections/export`"
        method="POST"
      >
        <input type="hidden" name="merchantId" :value="merchantIdExport" />
        <input type="hidden" name="merchant_code" :value="merchantCode" />
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="branch_id" :value="form.branch_id" />
        <input type="hidden" name="from_date" :value="from_date_export" />
        <input type="hidden" name="to_date" :value="to_date_export" />
        <input type="hidden" name="action_export" :value="action_export" />
        <input type="hidden" name="action" value="export" />
        <input id="downloadForm" type="submit" class="d-none" />
      </form>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import "@vuepic/vue-datepicker/dist/main.css";
  import "@vueform/multiselect/themes/default.css";
  import "@vuepic/vue-datepicker/dist/main.css";
  import moment from "moment";
  import AnalyticsService from "../../services/analytics.service";
  import CommonFunctionService from "../../services/commonFunction.service";
  import { startOfMonth } from "date-fns";
  import $ from "jquery";
  import TokenService from "../../services/token.service";
  import FormSearch from "../analytics/components/FormSearch.vue";
  import "datatables.net";
  
  export default {
    name: "pos-daily-collections-report",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      FormSearch
    },
    data() {
      return {
        isLoadDone: false,
        isLoadDoneDataTable: false,
        token: TokenService.getLocalAccessToken(),
        actionDownload: process.env.VUE_APP_BASE_URL,
        merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
        merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
        message: "",
        snackbar: null,
        loaderActive: false,
        transactionData: [],
        form: {
          branch_id: -1,
          from_date: moment(startOfMonth(new Date())),
          to_date: new Date(),
        },
        ESortDirectionSelect: {
          idx: null,
          field: '',
          direction: null,
        },
        ESortDirection: {
          Asc: "asc",
          Desc: "desc",
        },
        paymentMethods: [],
        action_export: 'csv',
        from_date_export: "",
        to_date_export: "",
        dataTable: null,
        tableData: [],
        columnSearch: []
      };
    },
    computed: {
      headersData() {
        return [
          {
            label: "Date Time",
            field: "date",
            hasSortBy: true,
          },
          {
            label: "Operation Id",
          },
          {
            label: "First Order",
            field: "first_order",
            hasSortBy: true,
          },
          {
            label: "Last Order",
            field: "last_order",
            hasSortBy: true,
          },
          {
            label: "Sub Total",
            field: "sub_total",
            hasSortBy: true,
          },
          {
            label: "Discount",
            field: "discount",
            hasSortBy: true,
          },
          {
            label: "Gst",
            field: "gst",
            hasSortBy: true,
          },
          {
            label: "Service Charge",
            field: "service_charge",
            hasSortBy: true,
          },
          {
            label: "Rounding",
            field: "rounding",
            hasSortBy: true,
          },
          {
            label: "Total",
            field: "total",
            hasSortBy: true,
          },
          {
            label: "Redemption",
            field: "redemption",
            hasSortBy: true,
          },
          {
            label: "Bill Count",
            field: "bill_count",
            hasSortBy: true,
          },
          {
            label: "Bill AVG",
            field: "bill_avg",
            hasSortBy: true,
          },
        ];
      },
    },
    methods: {
      format(date) {
        return CommonFunctionService.formatDateShow(date);
      },
  
      formatPrice(price) {
        price = parseFloat(price).toFixed(2);
        return 'S$ ' + price.toLocaleString('en-US', {minimumFractionDigits: 2});
      },
  
      exportFile(type = "csv") {
        this.action_export = type;
        this.from_date_export = this.dateFormat(this.form.from_date);
        this.to_date_export = this.dateFormat(this.form.to_date);
        setTimeout(() => {
          $("#downloadForm").click();
        }, 150);
      },
  
      dateFormat(date) {
        if (date == null) {
          return null;
        } else {
          return moment(date).format("YYYY-MM-DD");
        }
      },
  
      onChangeSearchParameter(value, fieldName) {
        this.form[fieldName] = value !== null ? value : '-1';
      },
  
      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      initializeDataTable() {
        this.dataTable = $(this.$refs.dataTable).DataTable({
          paging: false,
          searching: true,
          ordering: false,
          info: false,
        });
      },
  
      searchColumn(index) {
        this.dataTable.column(index).search(this.columnSearch[index]).draw();
      },
  
      handleChangeSortColumn(idx, field, payment_method_idx = 0) {
        if (
          this.ESortDirectionSelect.idx &&
          this.ESortDirectionSelect.idx == idx
        ) {
          this.ESortDirectionSelect.direction =
            this.ESortDirectionSelect.direction === this.ESortDirection.Asc
              ? this.ESortDirection.Desc
              : this.ESortDirection.Asc;
        } else {
          this.ESortDirectionSelect = {
            idx: idx,
            field: field,
            direction: this.ESortDirection.Asc,
          };
        }
  
  
        const direction = this.ESortDirectionSelect.direction;
        const totalOrder = this.transactionData.pop();
  
        this.transactionData.sort(function(item, item2) {
          if (field == "payment_method") {
            if (direction == "asc") {
              if (item.payment_methods[payment_method_idx]?.amount > item2.payment_methods[payment_method_idx]?.amount) return 1;
              if (item.payment_methods[payment_method_idx]?.amount < item2.payment_methods[payment_method_idx]?.amount) return -1;
            }
  
            if (direction == "desc") {
              if (item.payment_methods[payment_method_idx]?.amount < item2.payment_methods[payment_method_idx]?.amount) return 1;
              if (item.payment_methods[payment_method_idx]?.amount > item2.payment_methods[payment_method_idx]?.amount) return -1;
            }
  
            return 0;
          } else {
            if (direction == "asc") {
              if (item[field] > item2[field]) return 1;
              if (item[field] < item2[field]) return -1;
            }
  
            if (direction == "desc") {
              if (item[field] < item2[field]) return 1;
              if (item[field] > item2[field]) return -1;
            }
            
            return 0;
          }
        });
  
        this.transactionData.push(totalOrder);
      },
  
      getList() {
        this.isLoadDone = false;
        this.isLoadDoneDataTable = false;
        this.showLoader();
        var dataForm = {
          branch_id: this.form.branch_id,
          from_date: this.dateFormat(this.form.from_date),
          to_date: this.dateFormat(this.form.to_date),
        };
  
        AnalyticsService.posDailyCollectionsReport(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.transactionData = response.data.data.daily_collections;
              this.paymentMethods = response.data.data.payment_methods;
              const totalColumn = this.headersData.length + this.paymentMethods.length;
              this.columnSearch = [''];
  
              for (let i = 0; i < totalColumn; i++) {
                this.columnSearch.push("");
              }
  
              this.isLoadDone = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },
  
      showLoader() {
        this.loaderActive = true;
      },
  
      hideLoader() {
        this.loaderActive = false;
      },
    },
    watch: {
      isLoadDone(val) {
        if (val) {
          setTimeout(() => {
            this.initializeDataTable()
            this.isLoadDoneDataTable = true;
          }, 100);
        }
      }
    }
  };
  </script>
  <style scoped>
  table thead th:not(:first-child) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  table thead th > span {
    position: relative;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 2px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .date-time-dashboad .dp__input_wrap {
    min-width: 200px !important;
    width: 250px;
  }
  
  .custom-control-label label {
    padding-top: 14px !important;
  }
  
  .pt-custtom {
    padding-top: 7px;
  }
  
  tbody:not(.no-data) > tr:nth-child(2n + 1) {
    background: #f0f2f5;
  }
  
  tbody:not(.no-data) > tr:hover {
    background: #ebecee;
  }
  
  tbody > tr.total-row {
    font-weight: bold;
    color: #222;
  }
  
  
  .custom-table :not(caption)>*>* {
      border-right-width: 1px !important;
  }
  
  .custom-table :not(caption)>*>*:first-child {
      border-left-width: 1px !important;
  }
  </style>
  