<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="
            col-lg-10 col-md-10 col-12
            text-back
            d-flex
            align-items-center
            ps-2
          "
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backMember()"
            style="cursor: pointer"
          ></i>
          <div @click="backMember()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="card-body" style="padding: 0rem 0rem">
              <div
                class="
                  color-common
                  pb-2
                  font-weight-bolder
                  px-2
                  row
                  d-flex
                  align-items-center
                "
              >
                <div
                  class="
                    col-lg-10 col-md-10 col-12
                    ps-0
                    d-flex
                    align-items-center
                  "
                >
                  <strong class="title-page"
                    >MEMBER DETAILS - {{ form.name_text }}</strong
                  >
                  <span
                    :class="
                      form.block
                        ? 'badge badge-sm bg-gradient-success'
                        : 'badge badge-sm bg-gradient-danger'
                    "
                    style="margin-left: 20px"
                    >{{ form.block ? "Active" : "De-activated" }}</span
                  >
                </div>
                <div
                  class="
                    col-2 col-md-2 col-12
                    d-flex
                    justify-content-end
                    align-items-center
                  "
                >
                  <material-button
                    @click="changeStatus()"
                    class="btn btn-sm"
                  >
                    {{ form.block ? "De-activate" : "Activate" }}
                  </material-button>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex date-join mb-2">
            <div class="col-lg-3 col-md-6 col-12 ps-0">
              <em>Created: {{ form.create_at_text }} </em>
            </div>
            <div class="col-lg-3 col-md-6 col-12 ps-0">
              <em> Updated: {{ form.update_at_text }}</em>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-12 col-12 mx-auto card-custom-new mx-2 my-2">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  ID
                </div>
                <div class="col-lg-8 col-md-8 col-12">{{ form.id }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Member ID
                </div>
                <div class="col-lg-8 col-md-8 col-12">{{ form.member_id }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label d-flex align-items-center">
                  First Name
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <material-input
                    v-model="form.name"
                    extraClass="p-2"
                    id="m_name"
                    type="text"
                    name="m_name"
                    size="lg"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label d-flex align-items-center">
                  Last Name
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <material-input
                    v-model="form.last_name"
                    extraClass="p-2"
                    id="m_name"
                    type="text"
                    name="m_name"
                    size="lg"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Date Of Birth
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <material-input
                    id="m_date_of_birth"
                    type="date"
                    name="m_date_of_birth"
                    v-model="form.date_of_birth"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Gender
                </div>
                <div class="col-lg-8 col-md-8 col-12">{{ form.gender }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label d-flex align-items-center">
                  Email Address
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <material-input
                    v-model="form.email"
                    extraClass="p-2"
                    id="m_email"
                    type="text"
                    name="m_email"
                    size="lg"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Address 1
                </div>
                <div class="col-lg-8 col-md-8 col-12">{{ form.address_1 }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Address 2
                </div>
                <div class="col-lg-8 col-md-8 col-12">{{ form.address_2 }}</div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label d-flex align-items-center">
                  Mobile Number
                </div>
                <div class="col-lg-8 col-md-8 col-12" style="display: flex;">
                  <Multiselect :disabled="!allow_foreigner_phone_number" style="width: 180px;" v-model="form.phone_code" :options="countryData" placeholder="Select" label="label" :canClear="false" />
                  <material-input
                    v-model="form.phone_number"
                    extraClass="p-2"
                    id="m_phone_number"
                    type="text"
                    name="m_phone_number"
                    size="lg"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Occupation
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  {{ form.occupation }}
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Annual Income Range
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  {{ form.monthly_income_range }}
                </div>
              </div>
              <div class="row mt-3" style="align-items: center;">
                <div class="col-lg-4 col-md-4 col-12 label-form green-label">
                  Remarks
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <material-textarea v-model="form.remark" placeholder="Enter Remarks" />
                </div>
              </div>
              <div class="row mt-5" style="align-items: center;justify-content: center;">
                <div class="col-4 col-offset-3">
                  <material-button
                    @click="updateProfileAndRemark()"
                    class="btn btn-sm "
                  >
                    Update
                  </material-button>
                </div>
              </div>
              
            </div>
            <div class="col-lg-7 col-md-12 col-12 mx-auto mx-2 my-2">
                <div class="card-custom-new ps-3 pe-3" style="height: 100%;">
                  <div class="row">
                    <div class="col-lg-12 col-md-4 col-12 label-form green-label">
                      Member's Preference
                    </div>
                  </div>
                  
                  <div  v-for="(value, key) in memberPreferenceData" :key="key" class="row mt-4">
                      <div class="col-3" style="align-self: center;">
                          {{ key+1 +" : "+value.name }}
                      </div>
                      <div class="col-9">
                        <Multiselect @change="changeMemberPreference($event, value.id)" v-model="value.value_selected" :options="value.value_select" placeholder="Value" label="label" :canClear="false" />
                      </div>
                  </div>
                </div>
              </div>
            <div class="col-12 mx-auto my-2 ps-0">
              <div class="card-custom-new ps-3 pe-3 card-info">
                <div class="row">
                  <div class="col-lg-12 col-md-4 col-12 label-form green-label">
                    Member Dashboard
                  </div>
                </div>
                <div class="row d-flex date-join">
                  <div class="col-12">
                    <em>Created: {{ form.join_date_text }} </em>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">{{ form.amount_spent ?? 0 }}</h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Total Spend</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">
                            {{ form.wallet_balance_format ?? 0 }}
                          </h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Wallet Balance</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">
                            {{ form.amount_cashback_earned ?? 0 }}
                          </h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Total Cashback Earned</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">{{ form.amount_cashback_used ?? 0 }}</h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Total Cashback Used</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">person</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">{{ form.tier_name ? form.tier_name : '-' }}</h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Tier</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">moving</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">
                            {{ form.share_info_profile ? "100%" : "0%" }}
                          </h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Profile Match</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10">view_module</i>
                        </div>
                        <div class="text-end pt-1">
                          <h6 class="mb-0">{{ form.total_visit }}</h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Total Visits</strong>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-12">
                    <div class="card h-100">
                      <div class="card-header p-3 pt-2" style="height: 54px">
                        <div
                          class="
                            icon icon-lg icon-shape
                            money-box
                            text-center
                            border-radius-xl
                            mt-n4
                            position-absolute
                          "
                        >
                          <i class="material-icons opacity-10"
                            >calendar_today</i
                          >
                        </div>
                        <div class="text-end">
                          <h6 style="margin-bottom: -2px;">{{ form.last_visit_text ? form.last_visit_text : '-' }}</h6>
                          <h6>{{ form.branch_name_final }}</h6>
                        </div>
                      </div>
                      <hr class="dark horizontal my-0" />
                      <div class="card-footer p-3">
                        <strong class="mb-0">Last Visits</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pe-4">
            <div class="page-header align-items-start my-transacions">
              <span class="mask bg-white"></span>
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12 mx-auto">
                    <div class="row my-3 d-flex align-items-left">
                      <div class="col-lg-6 col-md-6 col-12 label-form green-label">
                        Member Transactions
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                        <div class="dropdown">
                          <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                            Download
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a class="dropdown-item" @click="exportFile('csv', 'getMyTransaction')" href="#">Export CSV</a>
                            </li>
                            <li>
                              <a class="dropdown-item" @click="exportFile('xlsx', 'getMyTransaction')" href="#">Export Excel</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3 d-flex align-items-left">
                      <div class="col-lg-6 col-md-6 col-12">
                        <input
                          class="nosubmit"
                          type="search"
                          id="keyword_search"
                          v-model="searchKeywordTransaction"
                          extraClass
                          name="keyword_search_transaction"
                          placeholder="Search by transaction set transaction id"
                          @input="onChangesearchKeyword(searchKeywordTransaction, 'getMyTransaction')"
                        />
                      </div>
                    </div>

                    <div class="row mt-4 mb-4">
                      <div class="col-12">
                        <div class="card-custom">
                          <div class="px-0 pb-2">
                            <div class="table-responsive p-0 custom-table">
                              <table class="table align-items-center mb-0">
                                <thead>
                                  <tr>
                                    <th class="text-center font-weight-bolder w-5">Receipt No</th>
                                    <th class="text-center font-weight-bolder w-5">Timestamp</th>
                                    <th class="text-center font-weight-bolder">
                                      Txn ID
                                    </th>
                                    <th class="text-center font-weight-bolder">Branch</th>
                                    <th class="text-left font-weight-bolder w-10">Member ID</th>
                                    <th class="text-center font-weight-bolder">First Name</th>
                                    <th class="text-center font-weight-bolder">Last Name</th>
                                    <th class="text-center font-weight-bolder">
                                      Phone No
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Txn Type
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Status
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Receipt Amount
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      New Balance
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Deducted Topup
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Deducted Bonus
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Deducted Cashback
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Added Topup
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Added Bonus
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Added Cashback
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="transaction.length">
                                  <tr v-for="(value, key) in transaction" :key="key">
                                    <td class="text-center text-xss w-5">
                                      {{ value?.receipt_no }}
                                    </td>
                                    <td class="text-center text-xss w-5">
                                      {{ value?.created_at_format }}
                                    </td>
                                    <td class="text-center text-xss w-25">
                                      {{ value?.transaction_id }}
                                    </td>
                                    <td class="text-center text-xss w-10">
                                      {{ value?.branch_name }}
                                    </td>
                                    <td class="text-center text-xss w-10">
                                      {{ value?.member_id_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.first_name }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.last_name }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.phone_number }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.transaction_type }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.status }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.total_amount_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.wallet_total_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.deducted_topup_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.deducted_bonus_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.deducted_cashback_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.topup_amount_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.topup_bonus_format }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value?.payment_amount_format }}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="17" class="text-center">
                                      No item available
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <pagination
                            v-if="paginationTransaction.last_page > 1"
                            :pagination="paginationTransaction"
                            :current_page="paginationTransaction.current_page"
                            :last_page="paginationTransaction.last_page"
                            pageTitle="getMyTransaction"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pe-4 mt-2">
            <div class="page-header align-items-start my-transacions">
              <span class="mask bg-white"></span>
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12 mx-auto">
                    <div class="row my-3 d-flex align-items-left">
                      <div class="col-lg-6 col-md-6 col-12 label-form green-label">
                        Member Rewards Collection
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                        <div class="dropdown">
                          <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                            Download
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a class="dropdown-item" @click="exportFile('csv', 'getListVocher')" href="#">Export CSV</a>
                            </li>
                            <li>
                              <a class="dropdown-item" @click="exportFile('xlsx', 'getListVocher')" href="#">Export Excel</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center my-3">
                      <ul class="member-detail-voucher-tab ps-0 pe-0">
                          <li class="nav-item active" id="nav_item_1" @click="changeType(1)">
                              <strong>Redeemable</strong>
                          </li>
                          <li class="nav-item" id="nav_item_5" @click="changeType(5)">
                              <strong>Redeemed</strong>
                          </li>
                          <li class="nav-item" id="nav_item_2" @click="changeType(2)">
                              <strong>Expired</strong>
                          </li>
                          <li class="nav-item" id="nav_item_0" @click="changeType(0)">
                              <strong>All</strong>
                          </li>
                      </ul>
                    </div>
                    <div class="row my-3 d-flex align-items-left">
                      <div class="col-lg-6 col-md-6 col-12">
                        <input
                          class="nosubmit"
                          type="search"
                          id="keyword_search"
                          v-model="searchKeywordVoucher"
                          extraClass
                          name="keyword_search"
                          placeholder="Search by voucher set title"
                          @input="onChangesearchKeyword(searchKeywordVoucher, 'getListVocher')"
                        />
                      </div>
                    </div>

                    <div class="row mt-4 mb-4">
                      <div class="col-12">
                        <div class="card-custom">
                          <div class="px-0 pb-2">
                            <div class="table-responsive p-0 custom-table">
                              <table class="table align-items-center mb-0">
                                <thead>
                                  <tr>
                                    <th class="text-center font-weight-bolder w-5">Voucher Id</th>
                                    <th class="text-center font-weight-bolder">
                                      Voucher Title
                                    </th>
                                    <th class="text-left font-weight-bolder w-10">Image</th>
                                    <th class="text-center font-weight-bolder">Exchanged Date</th>
                                    <th class="text-center font-weight-bolder">
                                      Redeemable Date
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Expired Date
                                    </th>
                                    <th class="text-center font-weight-bolder">
                                      Consuming Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="vouchers.length">
                                  <tr v-for="(value, key) in vouchers" :key="key">
                                    <td class="text-center text-xss w-5">
                                      VOU{{ value.id }}
                                    </td>
                                    <td class="text-left text-xss w-25">
                                      {{ value.reward_title }}
                                    </td>
                                    <td class="text-center text-xss w-10">
                                      <img
                                        :src="value.icon_url"
                                        class="border-radius-lg height-voucher"
                                      />
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value.exchanged_date }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value.redeemed_date }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value.expiry_date_text }}
                                    </td>
                                    <td class="text-center text-xss">
                                      {{ value.status_text }}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="10" class="text-center">
                                      No item available
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <pagination
                            v-if="pagination.last_page > 1"
                            :pagination="pagination"
                            :current_page="pagination.current_page"
                            :last_page="pagination.last_page"
                            pageTitle="getListVocher"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form :action="`${actionDownload}/merchant/download-member-transactions/${this.$route.params.id}`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword_transaction" :value="searchKeywordTransaction" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadTransactionForm" type="submit" class="d-none" />
    </form>
    <form :action="`${actionDownload}/merchant/download-member-rewards/${this.$route.params.id}`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword" :value="searchKeywordVoucher" />
      <input type="hidden" name="type" :value="filterType" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MemberService from "../../services/member.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import { ref } from "vue";
import $ from "jquery";
import TokenService from "../../services/token.service";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MemberPreferenceService from "../../services/memberPreference.service";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "sign-in",
  components: {
    Multiselect,
    MaterialSnackbar,
    MaterialLoading,
    Pagination,
    MaterialButton,
    MaterialTextarea,
    MaterialInput
  },
  setup() {
    const inputPassword = ref(null);

    const clearInputPassword = () => {
      inputPassword.value.clearInput();
    };

    return { inputPassword, clearInputPassword };
  },
  data() {
    return {
      form: {
        id: 0,
        member_id: "",
        name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        email: "",
        phone_number: "",
        address_1: "",
        address_2: "",
        postal_code: "",
        occupation: "",
        monthly_income_range: 1,
        marital_status: "",
        discovery_channel: "",
        country_id: "",
        education: "",
        create_at_text: "",
        update_at_text: "",
        tier_name: "",
        amount_spent: 0,
        amount_cashback_earned: 0,
        amount_cashback_used: 0,
        wallet_balance: 0,
        wallet_balance_format: 0,
        share_info_profile: 0,
        total_visit: 0,
        last_visit_text: "",
        join_date_text: "",
        name_text: "",
        block: 1,
        remark: "",
        phone_code: ""
      },
      loaderActive: false,
      snackbar: null,
      error: "",
      memberInfo: {},
      merchantCode: "",
      vouchers: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      isLoadData: false,
      filterType: 1,
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
      paginationTransaction: {},
      transaction: [],
      checkPage: 0,
      searchKeywordTransaction: "",
      searchKeywordVoucher: "",
      memberPreferenceData: [],
      allow_foreigner_phone_number: false,
      countryData: [],
    };
  },
  created() {
    this.getCountriesForDropdown();
    this.getMemberDetail();
    this.getList(this.page);
    this.getMembePreference();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = 'csv', title) {
      this.action_export = type;
      setTimeout(() => {
        if(title == 'getListVocher'){
          $('#downloadForm').click();
        }else{
          $('#downloadTransactionForm').click();
        }
      }, 150);
    },

    backMember() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/members" + "?page=" + page);
      } else {
        this.$router.push("/members");
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    getMemberDetail() {
      var memberId = this.$route.params.id;
      this.isLoadDoneData = false;
      this.showLoader();
      MemberService.getDataMemberDetail(memberId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.memberInfo = response.data.data.member;
            console.log("response.data.data.member", response.data.data.member);
            this.setDataprofile(this.memberInfo);
            this.isLoadDoneData = true;
            this.allow_foreigner_phone_number = response.data.data.allow_foreigner_phone_number;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    setDataprofile(memberInfor) {
      let formData = this.form;
      formData.id = memberInfor?.id_text;
      formData.member_id = memberInfor?.member_id_text;
      formData.name = memberInfor?.name;
      formData.last_name = memberInfor?.last_name;
      if(memberInfor && memberInfor.gender == 1) {
        formData.gender = "Male"
      } else if(memberInfor && memberInfor.gender == 2) {
        formData.gender = "Female"
      } else {
        formData.gender = ""
      }
      formData.email = memberInfor?.email;
      formData.address_1 = memberInfor?.address_1;
      formData.address_2 = memberInfor?.address_2;
      formData.phone_number = memberInfor?.phone_number;
      formData.occupation = memberInfor?.occupation;
      formData.date_of_birth = memberInfor?.date_of_birth;
      formData.create_at_text = memberInfor?.create_at_text;
      formData.update_at_text = memberInfor?.update_at_text;
      formData.tier_name = memberInfor?.tier_name;
      formData.wallet_balance = memberInfor?.wallet_balance;
      formData.wallet_balance_format = memberInfor?.wallet_balance_format;
      formData.amount_spent = memberInfor?.amount_spent;
      formData.amount_cashback_earned = memberInfor?.amount_cashback_earned;
      formData.amount_cashback_used = memberInfor?.amount_cashback_used;
      formData.share_info_profile = memberInfor?.share_info_profile;
      formData.last_visit_text = memberInfor?.last_visit_text;
      formData.total_visit = memberInfor?.total_visit;
      formData.join_date_text = memberInfor?.join_date_text;
      formData.block = memberInfor?.block;
      formData.name_text = memberInfor?.name_text;
      formData.monthly_income_range = this.annualIncomeRange(
        memberInfor?.monthly_income_range
      );
      formData.remark = memberInfor?.remark;
      formData.branch_name_final = memberInfor?.branch_name_final;
      formData.phone_code = memberInfor?.phone_code;
    },
    annualIncomeRange(value) {
      var occupationText = "";
      switch (value) {
        case 1:
          occupationText = "$0 - $3,000";
          break;
        case 2:
          occupationText = "$3,001 - $6,000";
          break;
        case 3:
          occupationText = "$6,001 - $9,000";
          break;
        case 4:
          occupationText = "Above $9,000";
          break;
        default:
          occupationText = "$0 - $3,000";
          break;
      }
      return occupationText;
    },
    changeStatus() {
      this.showLoader();
      var memberId = this.$route.params.id;
      MemberService.changeStatus(memberId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Change Member Success";
            this.getMemberDetail();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
          this.setTimeOutNotification();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    onChangesearchKeyword(searchKeyword, title) {
        this.searchKeyword = searchKeyword;
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(() => {
          if(title == 'getListVocher'){
            this.getListVocher(this.page);
          }else{
            this.getMyTransaction(this.page);
          }
        }, 1000);
    },

    changeType(type) {
      $('ul.member-detail-voucher-tab >li').removeClass('active');
      $('#nav_item_' + type).addClass("active")
      this.filterType = type
      this.getListVocher(this.page);
    },

    getList(page, pageTitle = null) {
      if(pageTitle == null || pageTitle == 'getMyTransaction'){
        this.getMyTransaction(page);
      }
      if(pageTitle == null || pageTitle == 'getListVocher'){
        this.getListVocher(page);
      }
    },
    getListVocher(page) {
      this.isLoadData = false;
      this.vouchers = [];
      this.showLoader();
      var memberId = this.$route.params.id;
      var dataForm = {
        type: this.filterType,
        search_keyword: this.searchKeyword,
        page: page,
      };
      MemberService.myRewards(memberId, dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.vouchers = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getMyTransaction(page) {
      this.isLoadData = false;
      this.transaction = [];
      this.showLoader();
      var memberId = this.$route.params.id;
      var dataForm = {
        search_keyword_transaction: this.searchKeyword,
        page: page,
      };
      MemberService.myTransaction(memberId, dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.transaction = response.data.data.items;
            this.paginationTransaction = response.data.data;
            this.page = this.paginationTransaction.current_page;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    updateProfileAndRemark() {
      this.isLoadData = false;
      this.showLoader();
      var memberId = this.$route.params.id;
      var dataForm = {
        member_id: memberId,
        phone_number: this.form.phone_number,
        name: this.form.name,
        last_name: this.form.last_name,
        email: this.form.email,
        date_of_birth: this.form.date_of_birth,
        remark: this.form.remark,
        phone_code: this.form.phone_code
      };
      MemberService.updateProfileAndRemark(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Update Member Profile Success";
            this.getMemberDetail();
            this.isLoadData = true;
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    getMembePreference() {
      var memberId = this.$route.params.id;
      this.isLoadData = false;
      this.showLoader();
      MemberPreferenceService.getListDropdown(memberId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.memberPreferenceData = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    changeMemberPreference(value_select_id, id) {
      if(value_select_id && id) {
        var memberId = this.$route.params.id;
        this.isLoadData = false;
        this.showLoader();
        MemberPreferenceService.changeMemberPreference({memberId, minty_merchant_preference_value_id: value_select_id, minty_merchant_preference_id: id}).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Update Member Preference Success";
              this.setTimeOutNotification();
              this.getMembePreference();
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },
    getCountriesForDropdown() {
      this.isLoadData = false;
      this.showLoader();
      MemberService.getCountriesForDropdown().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.countryData = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
  },
};
</script>
<style scoped>
.date-join {
  font-size: 15px;
  color: #222222;
}
.green-label {
  color: #0a7031;
}
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.member-detail-voucher-tab {
    margin: 0px !important;
    padding: 0px;
    width: 100% !important;
}

.member-detail-voucher-tab li {
    list-style: none;
    float: left;
    width: 25%;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.member-detail-voucher-tab li.active {
    border-bottom: 2px solid #133067 ;
    color: #133067;
}
.member-detail-voucher-tab li {
    border-bottom: 2px solid rgb(238, 234, 234);
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #133067 ;
  outline: none;
  border: none;
  border-bottom: 2px solid #133067 ;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.money-box {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
}

.money-box i {
  color: #000000 !important;
}

@media only screen and (max-width: 1200px) {
  .card-info .row.mt-5 > div:nth-child(n + 3) {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .card-info .row.mt-5 > div:nth-child(n + 2) {
    margin-top: 30px;
  }
}
</style>