export default {
    en: {
        // sidebar
        Dashboard: "Dashboard",
        Setting: 'Setting',
        Report: 'Reports',
        Booking: 'Booking',
        Marketing: 'Marketing',
        Product: 'Products',
        'Loyalty Members': 'Loyalty Members',
        'Order Management': 'Order Management',
        // 'Payment': 'Payment',
        'Subscription': 'Subscription',
        'POS Simulator': 'POS Simulator',
        // end sidebar

        // GLOBAL
        name: 'Name',
        status: 'Status',
        action: 'Action',
        logout: 'Logout',
        language: 'Language',
        Type: "Type",
        Count: "Count",
        Image: "Image",
        All: "All",
        Time: "Time",
        RUN: "RUN",
        ID: "ID",
        Display_Period: "Display Period",
        // END GLOBAL

        // subscription
        select: 'Select',
        package: 'Package',
        price: 'Price',
        nextBiling: 'Next Biling',
        dueAmount: 'Due Amount',
        paymentStatus: 'Payment Status',
        // status: 'Status',
        // action: 'Action',
        // end subscription

        // PAYMENT
        // name: 'Name',
        merchantCode: 'Merchant Code',
        stripeAccount: 'Stripe Account',
        viewPayout: 'view payout',
        // END PAYMENT

        // LOYALTY  MEMERS
        LoyaltyMemberlist: "LOYALTY MEMBER LIST",
        selectTier: "Select Tier",
        selectGender: "Select Gender",
        bulkAction: "BULK ACTION",
        download: "DOWNLOAD",
        // ID: "ID",
        phoneNo: "Phone No",
        // name: "Name",
        email: "Email",
        DOB: "DOB",
        gender: "Gender",
        joinDate: "Join Date",
        lastPurchase: "Last Purchase",
        lastActive: "Last Active",
        // status: "Status",
        // action: "Action",
        // END LOYALTY  MEMERS

        // Products
        PRODUCTS_MERCHANDISES_LIST: "PRODUCTS & MERCHANDISES LIST",
        ADD_PRODUCT_MERCHANDISE: "ADD PRODUCT/MERCHANDISE",
        // Type: "Type",
        SKU: "SKU",
        // Image: "Image",
        Name: "Name",
        Price: "Price",
        Stock: "Stock",
        // status: "Status",
        // action: "Action",
        ADD_GROUP: "ADD GROUP",
        Group_ID: "Group ID",
        Group_Name: "Group Name",
        Group_Description: "Group Description",
        // status: "Status",
        // action: "Action",
        // End Products

        // Marketing
        OVERALL_PERFORMANCE: "OVERALL PERFORMANCE",
        Total_Rewards_Used: "Total Rewards Used",
        Average_Spending_per_Rewards: "Average Spending per Rewards",
        Total_Revenue_from_Rewards: "Total Revenue from Rewards",
        REWARDS_CAMPAIGN: "REWARDS CAMPAIGN",
        // Count: "Count",
        Show: "Show",
        // All: "All",
        Active: "Active",
        Past: "Past",
        Deactive: "Deactive",
        Campain_Title: "Campain Title",
        Rewards: "Rewards",
        // Type: "Type",
        Duration: "Duration",
        Email_Sent: "Email Sent",
        SMS_Sent: "SMS Sent",
        Used: "Used",
        Revenue: "Revenue",
        // status: "Status",
        // action: "Action",
        // end Marketing

        // slide show
        SLIDES_SHOW_REPORT: "SLIDES SHOW REPORT",
        // Time: "Time",
        // RUN: "Run",
        // Count: "Count",
        Image_ID: "Image ID",
        Image_File: "Image File",
        // Image: "Image",
        Who_can_see_this_image: "Who can see this image",
        // Display_Period: "Display Period",
        Created_Date: "Created Date",
        Creator: "Creator",
        // status: "Status",
        EXPORT_TO_EXCEL: "EXPORT TO EXCEL",
        // end slide show

        // Voucher Redemption
        Voucher_Status: "Voucher Status",
        // All: "All",
        Redeemable: "Redeemable",
        Redeemed: "Redeemed",
        // Time: "Time",
        Search: "Search",
        Voucher_Type: "Voucher Type",
        Discount_Amount: "Discount Amount",
        Member_Gender: "Member Gender",
        Select_gender: "Select gender",
        // download: "Download",
        // Count: "Count",
        // RUN: "RUN",
        Columns: "Columns",
        Row_No: "Row No",
        Voucher_Name: "Voucher Name",
        Voucher_Code: "Voucher Code",
        Discount: "Discount",
        Bill_Total: "Bill Total",
        Member_Name: "Member Name",
        Member_ID: "Member ID",
        Member_Email: "Member Email",
        Member_Mobile: "Member Mobile",
        Issue_Date: "Issue Date",
        Redeem_Date: "Redeem Date",
        // end Voucher Redemption

        // TOP UP
        Top_Up_Amount: "Top Up Amount",
        Payment_Amount: "Payment",
        Paid_Amount_Expiry: "Expiry",
        Bonus_Amount: "Bonus",
        Bonus_Expiry: "Bonus Expiry",
        // action: "Action",
        // END TOP UP

        // BRANCHES
        // ID: "ID",
        Branch_Name: "Branch Name",
        Physical_Branch_Name: "Physical Branch Name",
        Virtual_Branch_Name: "Virtual Branch Name",
        Address: "Address",
        Operating_Hours: "Operating Hours",
        // status: "Status",
        // action: "Action",
        // END BRANCHES

        // SLIDES
        Slider_Images: "Slider Images",
        Image_Sequence: "Image Sequence",
        Upload_image: "Upload image",
        Who_can_see_this_slide: "Who can see this slide",
        // Display_Period: "Display Period",
        From: "From",
        Always_Display: "Always Display",
        Destination_link: "Destination link (User will be directed to this link when click on image from Member portal)",
        VIP: "VIP",
        LEVEL_1: "LEVEL 1",
        LEVEL_2: "LEVEL 2",
        PLATINUM: "PLATINUM",
        // END SLIDES

        // TIER
        Tier_Name: "Tier Name",
        Description: "Description",
        Show_tier_description_in_member_portal: "Show tier description in member portal",
        Show_tier_condition_in_member_portal: "Show tier condition in member portal",
        Condition_to_achieve_tier: "Condition to achieve tier",
        Share_full_profile: "Share full profile",
        Spending: "Spending",
        Duration_of_membership : "Duration of membership ",
        Condition_to_maintain_tier: "Condition to maintain tier",
        Cashback_Rule: "Cashback Rule",
        Point_Rule: "Ponit Rule",
        Standard_Rule: "Standard Rule",
        Superior_Rule: "Superior Rule",
        Welcome_Benefit: "Welcome Benefit",
        Voucher: "Voucher",
        Direct_Cashback: "Direct Cashback",
        Direct_Point: "Direct Point",
        Birthday_Benefit: "Birthday Benefit",
        Extra_Cashback: "Extra Cashback",
        Extra_Point: "Extra Point",
        SAVE : "SAVE ",
        DELETE: "DELETE",
        Tier_Badge: "Tier Badge",
        UPLOAD_ICON: "UPLOAD ICON",
        Upload_icon_minumum_150_150: "Upload icon minumum 150 x 150",
        VIP_TIER: "VIP TIER",
        PLATINUM_TIER: "PLATINUM TIER",
        save: "save",
        delete: "delete",
        // END TIER

        // Settings - Voucher set
        // ID: "ID",
        Title: "Title",
        // Image: "Image",
        // Type: "Type",
        Exchange_Value: "Exchange Value",
        Total: "Total",
        Sold: "Sold",
        Issued: "Issued",
        Number_Of_Vouchers: "Number Of Vouchers",
        Total_Rev: "Total Rev",
        // Used: "Used",
        Expiry_Date: "Expiry Date",
        // status: "Status",
        // action: "Action",
        // END Settings - Voucher set

        // Settings - Discount
        Rewards_Used: "Rewards Used",
        // Average_Spending_per_Rewards: "Average Spending per Rewards",
        // Total_Revenue_from_Rewards: "Total Revenue from Rewards",
        // ID: "ID",
        // Title: "Title",
        Code: "Code",
        // Used: "Used",
        // Revenue: "Revenue",
        // action: "Action",
        // END Settings - Discount

        // Settings - Cashback
        STANDARD_RULE: "STANDARD RULE",
        Hours: "Hours",
        Pending_time_before_cashback_can_be_redeemed: "Pending time before cashback can be redeemed",
        Pending_time_before_point_can_be_redeemed: "Pending time before point can be redeemed",
        Expiry_of_each_cashback_amount: "Expiry of each cashback amount",
        Expiry_of_each_point_amount: "Expiry of each point amount",
        Rebate: "Rebate",
        Save: "Save",
        Delete: "Delete",
        Cashback: "Cashback",
        Point: "Point",
        No_end_date: "No end date",
        SUPERIOR_RULE: "SUPERIOR RULE",
        Rule_Name: "Rule Name",
        Spend_Every: "Spend Every",
        Spend_Minimum: "Spend Minimum",
        Exchange: "Exchange",
        // END Settings - Cashback

        // Settings - General
        Merchant_Info: "Merchant Info",
        Merchant_ID: "Merchant ID",
        Merchant_Code: "Merchant Code",
        Contact_Infomation: "Contact Infomation",
        Contact_Emails: "Contact Emails(for member's queries)",
        Free_Text: "Free Text",
        Terms_Conditions: "Terms & Conditions",
        Privacy_Policy: "Privacy Policy",
        Terms_Conditions_Donation: "Terms & Conditions(Donation)",
        Merchant_Appearance: "Merchant Appearance",
        Merchant_Logo: "Merchant Logo",
        Loyalty_App_Theme_Color: "Loyalty App Theme Color (background)",
        Loyalty_App_Button_Color: "Loyalty App Button Color",
        Loyalty_App_Button_Text_Color: "Loyalty App Button Text Color",
        Loyalty_App_Text_Header_Color: "Loyalty App Text Header Color",
        // UPLOAD_ICON: "UPLOAD ICON",
        Upload_image_maximum_2M: "Upload image maximum 2M",
        Options: "Options",
        Allow_set_up_membership_tiering: "Allow set up membership tiering",
        Visits: "Visits",
        Allows_displaying_membership_spending: "Allows displaying membership spending",
        Allows_displaying_lifetime_earn_cashback: "Allows displaying lifetime earn cashback",
        Allows_displaying_redeemed_cashback: "Allows displaying redeemed cashback",
        Cashback_by_subtotal: "Calculate cashback base on subtotal",
        allow_member_to_signup_from_moo: "Allow member to sign up from MOO",
        Member_donation_transaction_history: "Allow displaying member's donation in Transaction History screen",
        Otp_test_mode: "OTP test mode",
        // Otp test mode: "Otp test mode",
        Member_Special_Rewards: "Member Special Rewards",
        Reward_per_transaction: "Reward per transaction",
        Collect_cashback_and_point: "Collect cashback and point from minty wallet",
        Profile_sharing_reward: "Profile sharing reward(Voucher sent when user inputs or shares profile to merchant)",
        Friend_inviting_reward: "Friend inviting reward(Voucher sent when user's friend sign up)",
        SMS_Setup: "SMS Setup",
        Character_count:"Character count",
        Notifications: "Notifications",
        // Notification: "Notification",
        Topup: "Topup",
        Deduction_From_Wallet: "Deduction From Wallet",
        Earn_Cashback: "Earn Cashback",
        In_App: "In-App",
        Email: "Email",
        SMS: "SMS",
        Notification_Content: "Notification Content",
        // Settings - General

        // POS simulator
        POS_Transaction: "POS Transaction",
        Update_POS_Transaction: "Update POS Transaction",
        Phone_Number: "Phone Number",
        By_Total_Amount: "By Total Amount",
        By_product: "By product",
        Total_Amount: "Total Amount",
        Discount_Code: "Discount Code",
        // Voucher_Code: "Voucher Code",
        Branch: "Branch",
        Staff: "Staff",
        Deduct_from_Wallet: "Deduct from Wallet",
        Payment_Amount_Topup: "Payment Amount",
        Top_Up: "Top Up",
        // Phone_Number: "Phone Number",
        Branch_Id: "Branch Id",
        // Staff: "Staff",
        // Top_Up_Amount: "Top Up Amount",
        Top_Up_Method: "Top Up Method",
        Cashback_Adjustment: "Cashback Adjustment",
        // Phone_Number: "Phone Number",
        // Branch_Id: "Branch Id",
        // Staff: "Staff",
        // Total_Amount: "Total Amount",
        Add_more_payment_amount: "Add more payment amount",
        Receipt_Total: "Receipt Total",
        Sub_Total: "Sub Total",
        Total_Before_Tax: "Total Before Tax",
        GST: "GST",
        Cess: 'Cess',
        Service_Charge: 'Service Change',
        Take_Away_Charge: 'Take Away Charge',
        Payment: "Payment",
        Wallet_Balance : "Wallet Balance",
        Paid_Topup: "Paid Topup",
        Topup_Bonus: "Topup Bonus",
        // Cashback: "Cashback",
        Address_1: "Address 1",
        Address_2: "Address 2",
        // Email: "Email",
        // Name: "Name",
        Tier: "Tier",
        Remarks: "Remarks",
        Preference: "Preference",
        // END POS simulator

        General: 'General',
        // Cashback: 'Cashback',
        // Discount: 'Discount',
        VoucherSet: 'Voucher Set',
        Voucher_Bundle: 'Voucher Bundle',
        // Tier: 'Tier',
        Slides: 'Slides',
        Branches: 'Branches',
        Virtual_Branches: 'Virtual Branches',
        TopUp: 'Top Up',
        // SMS: 'SMS',

        VoucherRedemption: 'Voucher Redemption',
        SlideShow: 'Slide Show',
        TopupReports: 'Topup',
        POSTransaction: 'POS transaction',
        PaidMembership: 'Paid Membership',
        VoidTopupReports: 'Void Topup',
        OrderItem: 'POS Order Item Report',
        PaidOnlineOrders: 'Paid Online Orders',
        WalletReports: 'Wallet',
        DonationReports: 'Donation',
        ServiceSlotSetting: 'Service & Slot Setting',
        BookingManagement: 'Booking Management'
    },
    cn: {
        // sidebar
        Dashboard: "仪表",
        Setting: '设置',
        Report: '报表',
        Booking: 'Booking',
        Marketing: '推广',
        Product: '产品',
        'Loyalty Members': '会员',
        'Order Management': 'Order Management',
        // 'Payment': '支付',
        'Subscription': '订阅计划',
        'POS Simulator': '收银机模拟器',
        // end sidebar

        // GLOBAL
        name: '姓名',
        status: '状态',
        action: '行动',
        logout: '登出',
        language: '语言',
        Type: "类型",
        Count: "计数",
        Image: "图像",
        All: "全部",
        Time: "时间",
        RUN: "执行",
        ID: "ID",
        Display_Period: "显示期",
        // END GLOBAL

        // subscription
        select: '选择',
        package: '包裹',
        price: '价格',
        nextBiling: '下一个喘息',
        dueAmount: '到期金额',
        paymentStatus: '支付状态',
        // status: '地位',
        // action: '行动',
        // end subscription

        // PAYMENT
        // name: '姓名',
        merchantCode: '商人代码',
        stripeAccount: '条纹帐户',
        viewPayout: '查看支付',
        // END PAYMENT

        // LOYALTY  MEMERS
        LoyaltyMemberlist: "忠诚度成员列表",
        selectTier: "选择层",
        selectGender: "选择性别",
        bulkAction: "批量行动",
        download: "下载",
        // ID: "ID",
        phoneNo: "电话号码",
        // name: "姓名",
        email: "电子邮件",
        DOB: "生日",
        gender: "性别",
        joinDate: "加入日期",
        lastPurchase: "上次购买",
        lastActive: "Last Active",
        // status: "地位",
        // action: "行动",
        // END LOYALTY  MEMERS

        // Products
        PRODUCTS_MERCHANDISES_LIST: "产品和商品清单",
        ADD_PRODUCT_MERCHANDISE: "添加产品/商品",
        // Type: "类型",
        SKU: "sku",
        // Image: "图像",
        Name: "姓名",
        Price: "价格",
        Stock: "库存",
        // status: "地位",
        // action: "行动",
        ADD_GROUP: "添加组",
        Group_ID: "组ID",
        Group_Name: "团队名字",
        Group_Description: "团体简介",
        // status: "地位",
        // action: "行动",
        // End Products

        // Marketing
        OVERALL_PERFORMANCE: "整体表现",
        Total_Rewards_Used: "总奖励使用",
        Average_Spending_per_Rewards: "平均每奖励支出",
        Total_Revenue_from_Rewards: "奖励总收入",
        REWARDS_CAMPAIGN: "奖励运动",
        // Count: "计数",
        Show: "展示",
        // All: "全部",
        Active: "积极的",
        Past: "过去的",
        Deactive: "停用",
        Campain_Title: "驻地标题",
        Rewards: "奖励",
        // Type: "类型",
        Duration: "期间",
        Email_Sent: "邮件已发送",
        SMS_Sent: "短信发送",
        Used: "用过的",
        Revenue: "收入",
        // status: "地位",
        // action: "行动",
        // end Marketing

        // slide show
        SLIDES_SHOW_REPORT: "幻灯片显示报告",
        // Time: "时间",
        // RUN: "跑步",
        // Count: "计数",
        Image_ID: "图像ID",
        Image_File: "图像文件",
        // Image: "图像",
        Who_can_see_this_image: "谁可以看到这个图像",
        // Display_Period: "显示期",
        Created_Date: "创建日期",
        Creator: "创造者",
        // status: "地位",
        EXPORT_TO_EXCEL: "导出到Excel",
        // end slide show

        // Voucher Redemption
        Voucher_Status: "凭证状态",
        // All: "All",
        Redeemable: "可赎回",
        Redeemed: "赎回",
        // Time: "Time",
        Search: "搜索",
        Voucher_Type: "优惠券类型",
        Discount_Amount: "折扣金额",
        Member_Gender: "成员性别",
        Select_gender: "选择性别",
        // download: "Download",
        // Count: "Count",
        // RUN: "RUN",
        Columns: "列",
        Row_No: "第",
        Voucher_Name: "代金券的名称",
        Voucher_Code: "优惠券代码",
        Discount: "折扣码",
        Bill_Total: "比尔总数",
        Member_Name: "成员名字",
        Member_ID: "会员ID",
        Member_Email: "会员电子邮件",
        Member_Mobile: "会员手机",
        Issue_Date: "签发日期",
        Redeem_Date: "兑换日期",
        // end Voucher Redemption

        // TOP UP
        Top_Up_Amount: "充值金额",
        Payment_Amount: "支付金额",
        Paid_Amount_Expiry: "付费到期",
        Bonus_Amount: "奖金金额",
        Bonus_Expiry: "奖金到期",
        // action: "行动",
        // END TOP UP

        // BRANCHES
        // ID: "ID",
        Branch_Name: "分店名称",
        Physical_Branch_Name: "Physical Branch Name",
        Virtual_Branch_Name: "Virtual Branch Name",
        Address: "地址",
        Operating_Hours: "营业时间",
        // status: "地位",
        // action: "行动",
        // END BRANCHES

        // SLIDES
        Slider_Images: "滑块图像",
        Image_Sequence: "图像序列",
        Upload_image: "上传图片",
        Who_can_see_this_slide: "谁能看到这张幻灯片",
        // Display_Period: "显示期",
        From: "从",
        Always_Display: "总是显示",
        Destination_link: "目标链接（从成员门户单击映像时，用户将被定向到此链接）",
        VIP: "VIP",
        LEVEL_1: "1级",
        LEVEL_2: "级别2",
        PLATINUM: "铂",
        // END SLIDES

        // TIER
        Tier_Name: "层名 *",
        Description: "描述",
        Show_tier_description_in_member_portal: "在会员门户中显示层描述",
        Show_tier_condition_in_member_portal: "Show tier condition in member portal",
        Condition_to_achieve_tier: "实现层的条件 *",
        Share_full_profile: "共享完整的个人资料",
        Spending: "开支",
        Duration_of_membership : "会员持续时间",
        Condition_to_maintain_tier: "维持层的条件 *",
        Cashback_Rule: "现金返还规则",
        Point_Rule: "Ponit Rule",
        Standard_Rule: "标准规则",
        Superior_Rule: "上级规则",
        Welcome_Benefit: "欢迎收益",
        Voucher: "代金券",
        Direct_Cashback: "直接现金返还",
        Direct_Point: "Direct Point",
        Birthday_Benefit: "生日利益",
        Extra_Cashback: "额外的现金返还",
        Extra_Point: "Extra Point",
        SAVE : "保存",
        DELETE: "删除",
        Tier_Badge: "层徽章",
        UPLOAD_ICON: "上传图标",
        Upload_icon_minumum_150_150: "上传图标Minumum 150 x 150",
        VIP_TIER: "VIP层",
        PLATINUM_TIER: "白金层",
        save: "保存",
        delete: "删除",
        // END TIER

        // Settings - Voucher set
        // ID: "ID",
        Title: "标题",
        // Image: "图像",
        // Type: "类型",
        Exchange_Value: "交换价值",
        Total: "全部的",
        Issued: "发布",
        Sold: "Sold",
        Number_Of_Vouchers: "Number Of Vouchers",
        Total_Rev: "总复兴",
        // Used: "用过的",
        Expiry_Date: "到期日",
        // status: "地位",
        // action: "行动",
        // END Settings - Voucher set

        // Settings - Discount
        Rewards_Used: "使用的奖励",
        // Average_Spending_per_Rewards: "平均每奖励支出",
        // Total_Revenue_from_Rewards: "奖励总收入",
        // ID: "ID",
        // Title: "标题",
        Code: "代码",
        // Used: "用过的",
        // Revenue: "收入",
        // action: "行动",
        // END Settings - Discount

        // Settings - Cashback
        STANDARD_RULE: "标准规则",
        Hours: "小时",
        Pending_time_before_cashback_can_be_redeemed: "赎回现金返还之前等待时间",
        Pending_time_before_point_can_be_redeemed: "Pending time before point can be redeemed",
        Expiry_of_each_cashback_amount: "每个现金返还金额的到期",
        Expiry_of_each_point_amount: "Expiry of each point amount",
        Rebate: "回扣",
        Save: "保存",
        Delete: "删除",
        Cashback: "现金返还",
        Point: "Point",
        No_end_date: "没有结束日期",
        SUPERIOR_RULE: "上级规则",
        Rule_Name: "规则名称",
        Spend_Every: "花费每一个",
        Exchange: "Exchange",
        // END Settings - Cashback

        // Settings - General
        Merchant_Info: "商人信息",
        Contact_Infomation: "接触信息",
        Contact_Emails: "联系电子邮件",
        Free_Text: "Free Text",
        Terms_Conditions: "Terms & Conditions",
        Privacy_Policy: "Privacy Policy",
        Terms_Conditions_Donation: "Terms & Conditions(Donation)",
        Merchant_Appearance: "商人外观",
        Merchant_Logo: "商人徽标",
        Loyalty_App_Theme_Color: "忠诚度应用主题颜色（背景）",
        Loyalty_App_Button_Color: "忠诚度应用按钮颜色",
        Loyalty_App_Button_Text_Color: "忠诚度应用按钮文字颜色",
        Loyalty_App_Text_Header_Color: "忠诚度应用文本标题颜色",
        // UPLOAD_ICON: "上传图标",
        Upload_image_maximum_2M: "上传图像最多2m",
        Options: "选项",
        Allow_set_up_membership_tiering: "允许设置会员分层",
        Visits: "Visits",
        Allows_displaying_membership_spending: "允许显示会员支出",
        Allows_displaying_lifetime_earn_cashback: "允许显示终生赚取现金返还",
        Allows_displaying_redeemed_cashback: "允许显示赎回的现金返还",
        Cashback_by_subtotal: "Calculate cashback base on subtotal",
        allow_member_to_signup_from_moo: "Allow member to sign up from MOO",
        Member_donation_transaction_history: "Allow displaying member's donation in Transaction History screen",
        Otp_test_mode: "OTP测试模式",
        // Otp test mode: "OTP测试模式",
        Member_Special_Rewards: "会员特别奖励",
        Reward_per_transaction: "Reward per transaction",
        Collect_cashback_and_point: "Collect cashback and point from minty wallet",
        Profile_sharing_reward: "个人资料共享奖励（用户输入或分享商人的配置文件时发送的代金券）",
        Friend_inviting_reward: "朋友邀请奖励（用户朋友注册时发送代金券）",
        SMS_Setup: "SMS设置",
        Character_count:"角色计数",
        Notifications: "通知",
        // Notification: "通知",
        Topup: "上层",
        Deduction_From_Wallet: "从钱包中扣除",
        Earn_Cashback: "赚取现金返还",
        In_App: "应用内",
        Email: "电子邮件",
        SMS: "短信余额",
        Notification_Content: "通知内容",
        // Settings - General

        // POS simulator
        POS_Transaction: "POS交易",
        Update_POS_Transaction: "Update POS交易",
        Phone_Number: "电话号码",
        By_Total_Amount: "总金额",
        By_product: "通过产品",
        Total_Amount: "总金额",
        Discount_Code: "折扣码",
        // Voucher_Code: "优惠券号",
        Branch: "分行",
        Staff: "职员*",
        Deduct_from_Wallet: "从钱包中扣除",
        Payment_Amount_Topup: "支付金额",
        Top_Up: "充值金额",
        // Phone_Number: "电话号码",
        Branch_Id: "分支ID",
        // Staff: "职员",
        // Top_Up_Amount: "充值金额",
        Top_Up_Method: "充气方法",
        Cashback_Adjustment: "现金返还调整",
        // Phone_Number: "电话号码",
        // Branch_Id: "分支ID",
        // Staff: "职员",
        // Total_Amount: "总金额",
        Add_more_payment_amount: "增加更多付款金额",
        Receipt_Total: "账单总额",
        Sub_Total: "Sub Total",
        Total_Before_Tax: "Total Before Tax",
        GST: "GST",
        Cess: 'Cess',
        Service_Charge: 'Service Change',
        Take_Away_Charge: 'Take Away Charge',
        Payment: "支付",
        Wallet_Balance : "钱包余额",
        Paid_Topup: "付费充值",
        Topup_Bonus: "奖金",
        // Cashback: "返现",
        Address_1: "地址 1",
        Address_2: "地址 2",
        // Email: "电邮",
        // Name: "姓名",
        Tier: "会员等级",
        Remarks: "备注信息",
        Preference: "会员偏好",
        // END POS simulator,

        General: "通用设置",
        // Cashback: "现金返还",
        // Discount: "折扣码",
        Voucher_Bundle: 'Voucher Bundle',
        // Tier: "会员等级",
        Slides: "滑动图片",
        Branches: "分行",
        Virtual_Branches: 'Virtual Branches',
        TopUp: "充值设置",
        // SMS: "短信余额",

        VoucherRedemption: "优惠券兑换",
        SlideShow: "会员页幻灯片",
        TopupReports: "充值记录",
        POSTransaction: "收银机订单",
        PaidMembership: 'Paid Membership',
        VoidTopupReports: 'Void Topup',
        OrderItem: 'POS Order Item Report',
        PaidOnlineOrders: 'Paid Online Orders',
        WalletReports: 'Wallet',
        DonationReports: 'Donation',
        ServiceSlotSetting: 'Service & Slot Setting',
        BookingManagement: 'Booking Management'
    }
}